@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
}

body {
  background-color: #e5e9ef;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: black;
}

.dark {
  background-color: #1a202c;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: #f7fafc;
}

body.dark .search-input {
  background-color: #2D3748;
  color: #f7fafc;
}

.invert-colors {
  filter: invert(100%);
  transition: filter 0.3s ease;
}

.nav-menu {
  @extend .no-select;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f7fafc;
  padding-right: 1.5rem;
  z-index: 2;
}
.dark .nav-menu {
  background-color: #1f2937;
}

/* Light mode styles */
.nav-link {
  color: #1f2937;
  transition: color 0.3s;
  font-weight: 600;
}

.nav-link-active {
  color: #1a60d3;
  transition: color 0.3s;
}

/* Dark mode styles */
.dark .nav-link {
  color: #e5e7eb
}

.dark .nav-link-active {
  color: #64a7fa;
}

/* Hover effect for visual feedback */
.nav-link:hover {
  color: #6b7280;
}

.dark .nav-link:hover {
  color: #9ca3af;
}

/*Modal*/
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e5e9ef;
  border: 1px solid gray;
  z-index: 10;
  max-height: calc(100vh - 1rem);
  max-width: calc(100vw - 1rem);
  box-sizing: border-box;
  padding: 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.dark .modal-container {
  background-color: #1a202c;
  border-color: #4a5568;
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content > * {
  flex: 1;
  max-width: 50%;
  box-sizing: border-box;
}

.modal-content > *:only-child {
  max-width: 75%;
  justify-content: center;
}

.viewer-container {
  @extend .no-select;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.5em;
  margin-left: 0.5em;
}

@media (min-width: 720px) {
  .viewer-container {
    flex-direction: row;
    justify-content: center;
  }
}

.card-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.75rem 0.5rem;
  min-width: 14rem;
  min-height: 32rem;
}

@media (max-width: 768px) {
  .modal-container {
    max-width: 95%;
    min-width: 65%;
  }
  .card-details-container {
  min-width: 10rem;
  }
}

.card-details {
  @extend .selectable;
  margin-top: 1rem;
  font-size: 1.1rem;
  max-width: 26.25rem;
}

.card-text {
  @extend .selectable;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.75rem;
  text-align: center;
  min-height: 3rem;
}

.card-cost {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.75rem;
  margin-top: -1.5rem;
}

.card-box {
  width: 8.5rem;
  height: 16rem;
  margin: 0.125rem;
  padding-top: 0.25rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(122, 122, 122, 0.2);
}

.card-img {
  width: 100%;
  height: 12rem;
  object-fit: cover;
}

.card-img-hero {
  width: 100%;
  height: 9.5rem;
  object-fit: cover;
}

.card-img-name {
  @extend .selectable;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -0.625rem;
  height: 2.5rem;
}

body.dark .close-button:hover {
  text-shadow: 0 0.0625rem 0.5rem #a2a9af;
}

/*Buttons*/
.disabled-btn {
  opacity: 0.3;
}

.smallButtonHover:hover {
  text-shadow: 0 0.0625rem 0.25rem #737d94;
}

body.dark .smallButtonHover:hover {
  text-shadow: 0 0.0625rem 0.25rem #a2a9af;
}

.iconButtonHover:hover {
  text-shadow: 0 0.0625rem 0.5rem #737d94;
}

body.dark .iconButtonHover:hover {
  text-shadow: 0 0.0625rem 0.5rem #a2a9af;
}

.wideButtonHover:hover {
  text-shadow: 0 0.0625rem 1rem #737d94;
}

body.dark .wideButtonHover:hover {
  text-shadow: 0 0.0625rem 1rem #a2a9af;
}

//Carousel
.nr-btn.active {
  font-weight: 600;
  color: #269826;
}

@keyframes appear {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.aspect-ratio-square::before {
  float: left;
  padding-top: 100%;
  content: "";
}

.aspect-ratio-square::after {
  display: block;
  content: "";
  clear: both;
}

.card-item-container {
  display: flex;
  align-items: flex-start;
}

.text-line-wrap {
  overflow-wrap: break-word;
  white-space: normal;
}

.no-select {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
}

.selectable {
  -webkit-user-select: text; /* Chrome, Safari, Opera */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
  caret-color: transparent;
}
